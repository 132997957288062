$fontPath: "../static/fonts";

@import "~environment";
@import "~styleguide/base/index.scss";
@import "~styleguide/all";

body {
  &:before {
    position: fixed;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../static/images/bg-pattern-light.jpg");
    background-size: cover;
    background-position-y: bottom;
    background-repeat: no-repeat;
    z-index: -3;
  }

  .ccm-settings-summoner {
    transition: all 250ms ease-in-out;
  }

  &:has(.navbar__flyout.is-open) {
    .ccm-settings-summoner {
      translate: -200%;
    }
  }

  @include bp(desktop) {
  }

  &.v-card {
    .header {
      display: none;
    }
  }

  &.theme--dark {
    &:before {
      background-image: url("../static/images/bg-pattern-dark.jpg");
    }
  }

  &.is-blurry {
    overflow: hidden;
    &:before {
      filter: blur(2px);
    }
    .main-content {
      filter: blur(5px);
    }
  }
}

.car-teaser-grid {
  display: flex;
  flex-wrap: wrap;

  > * {
    width: calc(25% - 20px);
    margin: 10px;
  }

  *:last-child {
    margin-right: auto;
  }
}

.main-content {
  &__main {
    margin-top: 30px;

    &--v-card {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__subheadline {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  &.is-blurry {
    filter: blur(5px);
  }
}
